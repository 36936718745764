import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { UserService } from "../services/user.service";
import { TradingService, withdraw } from '../services/trading.service';
import { NotificationService } from '../notification/notification.service';
import * as XLSX from 'xlsx';
import { PagerService } from "../services/pager.service";
import { NgForm } from '@angular/forms';
import { KycserviceService } from '../services/kycservice.service'
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from 'src/app/services/socket.service';
import { MatDialog } from '@angular/material/dialog';
import { WithdrawConfirmPopupDialogComponent } from '../withdraw-confirm-popup/withdraw-confirm-popup.component';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {

  //Order History Ag Grid
  public cyptoWithdrawHistoryColumnDefs: ColDef[] = [
    { field: 'txnid', headerName: 'TXN ID', flex: 1,  width: 120 },
    { field: 'txHash', headerName: 'TXN Hash', width: 220, flex: 1 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'amount', headerName: 'Amount', width: 120 },
    { field: 'withdraw_fee', headerName: 'Fee', width: 120 },
    { field: 'address', headerName: 'Address', width: 400 },
    { field: 'note', headerName: 'Note', flex: 1 },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'createdAt', headerName: 'Created AT', sort: 'desc', width: 200,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    },
  ];
  public cyptoWithdrawHistoryDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable:true,
    enableCellChangeFlash: true
  };
  public cyptoWithdrawHistoryRowData: any[] = [];
  @ViewChild(AgGridAngular) cyptoWithdrawHistoryAgGrid: AgGridAngular;
  cyptoWithdrawHistoryGridApi!: GridApi;


  //FIAT CURRENCY

  public fiatWithdrawHistoryColumnDefs: ColDef[] = [
    { field: 'otctxnid', headerName: 'Blockoville txnID', flex: 1 },
    { field: 'txntype', headerName: 'Payment Type', width: 250 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'amount', headerName: 'Amount', width: 100 },
    { field: 'transfer_amount', headerName: 'Transfer', width: 100 },
    { field: 'withdraw_fee', headerName: 'Fee', width: 100 },
    { field: 'txnid', headerName: 'TXN ID', flex: 1 },
    { field: 'status', headerName: 'Status' },
    {
      field: 'createdAt', headerName: 'Created AT', sort: 'desc',  flex:1 ,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    },
  ];
  public fiatWithdrawHistoryDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable:true,
    enableCellChangeFlash: true
  };
  public fiatWithdrawHistoryRowData: any[] = [];
  @ViewChild(AgGridAngular) fiatWithdrawHistoryAgGrid: AgGridAngular;
  fiatWithdrawHistoryGridApi!: GridApi;

  cryptoWithdraw: any = {
    _csrf: '',
    currency: 'BTC',
    address: '',
    amount: '',
    note: '',
    des_tag: '',
    assetid: 'BTC',
  };

  fiatWithdraw: any = {
    _csrf: '',
    currency: 'EUR',
    bank_name: '',
    amount: '',
    account_holder_name: '',
    account_number: '',
    iban_number: '',
    ifsc_code: '',
    bic_code: '',
  }
  placeHold: any;
  submit: boolean = false;
  valid: boolean = false;
  userd: any;
  placeHoldwhitelsit: any;
  userId: string = '';
  feeError: string = '';
  showTagWithdraw : boolean = false;
  showTagWhitelist : boolean = false;

  formwhitelist: any = {
    _csrf: '',
    currency: 'BTC',
    address: '',
    blockchain: '',
    assetid: 'BTC',
  };
  formDeleteWhiteListAddress: any = {};
  whiteListAddressProcess: any = {};
  deleteWhiteListAddressProcess: any = {};
  whitelist_status: boolean = false;

  fiatCurrencyActive: any = {};
  cryptoCurrencyActive: any = {};
 
  blockchainConfig: any = [];
  activeBlockchainWithdraw: any = [];
  activeBlockchainWhitelist: any = [];

  receiveFiatAmount: number = 0;
  feeFiatAmount: number = 0;

  withdrawAmountUpdate = new Subject<string>();
  constructor(
    private userService: UserService,
    private router: Router,
    private tradeService: TradingService,
    private actroute: ActivatedRoute,
    private notifyService: NotificationService,
    private pagerService: PagerService,
    public dialog: MatDialog,
    private kycService: KycserviceService,
    private storageService: StorageService,
    public translate: TranslateService,
    private readonly socketService: SocketService,

  ) {
    this.userId = this.userService.getUserId();

    this.userd = this.userService.getUserDetails();

    this.kycService.getUserKYC().subscribe(res => {
      if (res.status == 'Pending' || res.status == 'Rejected' || res.status == 'Draft') {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist")
        } else {
          this.notifyService.showError("", "Not Accesible until KYC Verification")
        }


        this.router.navigate(['profile'])
      }
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist")
      } else {
        this.notifyService.showError("", "Not Accesible until KYC Verification")
      }
      this.router.navigate(['profile'])
    })
    setTimeout(() => {
      this.socketService.subscribeWalletDataChannels(this.userId);
    }, 1000);

    this.withdrawAmountUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.changeCryptoAmount()
      });
  }

  fiatButton: boolean = true;
  cryptoButton: boolean = true;
  fiatErrorMessage: string = '';
  cryptoErrorMessage: string = '';

  wallets: any = [];
  cryptoWallets: any = [];
  fiatWallets: any = [];

  public data: any = [];
  public wallist: any = [];
  public currencydetails: any = [];
  public currencyActiveCurrencyType = "CRYPTO";
  public whiteListAddresses: any = [];
  public withdrawhistorylist: any = [];
  public exportfiatwithdrawhistory: any = [];
  public fiatwithdrawlist: any = [];
  public allWhiteListAddresses: any = [];
  fileName: string;
  searchObj = {
    currency: '',
  }

  currentPagefiat: number;
  available_balance: any;
  withdrawfees: any;
  withdraw_get_amount: any;
  calc_part: boolean = false;
  fiatcurrencylist = [];
  payment_show = true;
  clear_wallet_id = true;
  updatedWallet: any = {};
  exportwithdrawhistory: Array<any> = [];
  ngOnInit(): void {
    this.data = {};
    this.currencydetails = [];
    this.wallist = [];
    this.cryptoWallets = [];
    this.fiatWallets = [];

    this.csrf();
    this.placeHold = {
      currency: '',
      address: '',
      amount: '',
      note: '',
      des_tag: '',
      error: {
        currency: '',
        address: '',
        amount: '',
        note: '',
        des_tag: ''
      }
    }
    this.placeHoldwhitelsit = {
      currency: '',
      address: '',
      status: '',
      des_tag: '',
      error: {
        currency: '',
        address: '',
        status: '',
        des_tag: ''
      }
    }

    this.actroute.queryParams.subscribe(params => {
      if (params.currencyActiveCurrencyType == "CRYPTO") {
        this.cryptoWithdraw.currency = params.currencyActive;
        this.currencyActiveCurrencyType = params.currencyActiveCurrencyType.toLowerCase() + "-withdraw";
      } else if (params.currencyActiveCurrencyType == "FIAT") {
        this.fiatWithdraw.currency = params.currencyActive;
        this.currencyActiveCurrencyType = params.currencyActiveCurrencyType.toLowerCase() + "-withdraw";
      }
    })

    this.socketService.onWalletDataReceive().subscribe(updatedWallet => {
      this.updatedWallet = updatedWallet;
      var foundIndex = this.cryptoWallets.findIndex(x => x._id == this.updatedWallet._id);
      if (foundIndex >= 0) {
        this.cryptoWallets[foundIndex] = this.updatedWallet;
      } else {
        this.cryptoWallets = [this.updatedWallet, ...this.cryptoWallets]
      }
      if (this.fiatCurrencyActive.currency == this.updatedWallet.currency) {
        this.fiatCurrencyActive.availableBalance = this.updatedWallet.availableBalance
      }
      if (this.cryptoCurrencyActive.currency == this.updatedWallet.currency) {
        this.cryptoCurrencyActive.availableBalance = this.updatedWallet.availableBalance
      }
    })
  }
  csrf() {
    this.userService.csrf().subscribe((response) => {
      this.data['_csrf'] = response._csrf;
      this.cryptoWithdraw._csrf = response._csrf;
      this.formwhitelist._csrf = response._csrf;
      this.fiatWithdraw._csrf = response._csrf;
      this.formDeleteWhiteListAddress._csrf = response._csrf;
      this.deleteWhiteListAddressProcess._csrf = response._csrf;
      this.whiteListAddressProcess._csrf = response._csrf;
      this.fetchWithdraw();
      this.getuserWhitelistStatus();
      this.fetchWithdrawFiat();
      this.getcwaluserbalance();
      this.getAllBlockchainConfig();
      if (this.actroute.snapshot.paramMap.get('id') != null) {
        var res = this.router.url.split("/");
        if (res[1] == "withdrawsubmit") {
          this.withdrawconfirm(this.actroute.snapshot.paramMap.get('id'));
        }
        if (res[1] == "withdrawcancel") {
          this.withdrawcancel(this.actroute.snapshot.paramMap.get('id'));
        }
        if (res[1] == "whitelist") {
          this.whitelist_process(this.actroute.snapshot.paramMap.get('id'));
        }
      } else { }
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", 'Ilmnes viga, proovige uuesti.')
      } else {
        this.notifyService.showError("", 'Error occured, please try again.')
      }
    });
  }

  ngOnDestroy() {
    this.socketService.unSubscribeWalletDataConfigChannels(this.userId);

  }

  changeCryptoAmount() {
    this.cryptoButton = true;
    let Message = '';
    let Messagess = '';
    if (this.cryptoWithdraw.amount == '' || this.cryptoWithdraw.amount == null || Number(this.cryptoWithdraw.amount) < 0) {
      Messagess = "Ei ole kehtiv summa";
      Message = 'Not a valid Amount';
    } /*else if (Number(this.cryptoWithdraw.amount) < this.cryptoCurrencyActive?.currency_config?.WithdrawMinimum) {
      Messagess = "Lubatud minimaalne kogus on " + this.cryptoCurrencyActive?.currency_config?.WithdrawMinimum;
      Message = 'Allowed minimum amount is ' + this.cryptoCurrencyActive?.currency_config?.WithdrawMinimum;
    } else if (Number(this.cryptoWithdraw.amount) > this.cryptoCurrencyActive?.currency_config?.WithdrawMaximum) {
      Messagess = "Lubatud maksimaalne kogus on " + this.cryptoCurrencyActive?.currency_config?.WithdrawMaximum;
      Message = 'Allowed maximum amount is ' + this.cryptoCurrencyActive?.currency_config?.WithdrawMaximum;
    } */ else if (Number(this.cryptoWithdraw.amount) > this.cryptoCurrencyActive?.availableBalance) {
      Messagess = "Pole piisavalt tasakaalu";
      Message = "Don't have a enough balance";
    }  else if (this.cryptoWithdraw.currency == 'XRP' && (this.cryptoCurrencyActive?.availableBalance - Number(this.cryptoWithdraw.amount)) < 10) {
      Messagess = "Reservtagatisraha vähemalt 10 XRP";
      Message = "Reserve deposit of at least 10 XRP";
    }else {
      this.cryptoButton = false;
      this.cryptoWithdrawFees();
    }

    if (this.cryptoButton == true) {
      if (this.storageService.getLanguage() == 'es') {
        this.cryptoErrorMessage = Messagess;
      } else {
        this.cryptoErrorMessage = Message;
      }
    }
    else {
      this.cryptoErrorMessage = '';
    }

  }

  changeFiatAmount() {
    this.fiatButton = true;
    let Message = '';
    let Messagess = '';
    if (this.fiatWithdraw.amount == '' || this.fiatWithdraw.amount == null) {
      Messagess = "Ei ole kehtiv summa";
      Message = 'Not a valid Amount';
    } else if (Number(this.fiatWithdraw.amount) < this.fiatCurrencyActive?.currency_config?.WithdrawMinimum) {
      Messagess = "Lubatud minimaalne kogus on " + this.fiatCurrencyActive?.currency_config?.WithdrawMinimum;
      Message = 'Allowed minimum amount is ' + this.fiatCurrencyActive?.currency_config?.WithdrawMinimum;
    } else if (Number(this.fiatWithdraw.amount) > this.fiatCurrencyActive?.currency_config?.WithdrawMaximum) {
      Messagess = "Lubatud maksimaalne kogus on " + this.fiatCurrencyActive?.currency_config?.WithdrawMaximum;
      Message = 'Allowed maximum amount is ' + this.fiatCurrencyActive?.currency_config?.WithdrawMaximum;
    } else if (Number(this.fiatWithdraw.amount) > this.fiatCurrencyActive?.availableBalance) {
      Messagess = "Pole piisavalt tasakaalu";
      Message = "Don't have a enough balance";
    } else {
      this.fiatButton = false;
    }

    if (this.fiatButton == true) {
      if (this.storageService.getLanguage() == 'es') {
        this.fiatErrorMessage = Messagess;
      } else {
        this.fiatErrorMessage = Message;
      }
    }
    else {
      this.fiatErrorMessage = '';
    }

    var percentageFee = (this.fiatWithdraw.amount * this.fiatCurrencyActive?.currency_config?.WithdrawFee / 100)

    if (percentageFee > this.fiatCurrencyActive?.currency_config?.MinimumFee) {
      this.fiatCurrencyActive.receive_amount = this.fiatWithdraw.amount - percentageFee;
      this.fiatCurrencyActive.fee_amount = percentageFee;
    } else {
      this.fiatCurrencyActive.receive_amount = this.fiatWithdraw.amount - this.fiatCurrencyActive?.currency_config?.MinimumFee;
      this.fiatCurrencyActive.fee_amount = this.fiatCurrencyActive?.currency_config?.MinimumFee;
    }
  }

  getuserWhitelistStatus() {
    this.tradeService.getuserWhitelistStatus().subscribe((result) => {
      if (result) {
        this.allWhiteListAddresses = result.data;
        this.getwhitelistedaddress();
      }
    })
  }

  getcwaluserbalance() {
    this.tradeService.getCwalUserBalance(this.data, 'getcwaluserbalance').subscribe((result) => {
      if (result.wallist) {
        this.wallets = result.wallist;
        for (let w = 0; w < this.wallets.length; w++) {
          if (this.wallets[w].currency_type == 'CRYPTO') {
            this.cryptoWallets.push(this.wallets[w]);
          }

          if (this.wallets[w].currency_type == 'FIAT') {
            this.fiatWallets.push(this.wallets[w]);
          }
        }

        this.changeCryptoCurrency(this.cryptoWithdraw.currency);
        this.changeFiatCurrency(this.fiatWithdraw.currency);
      }
    }, (err) => {
      console.log(err);
    });
  }
  round(value, float = 0) {
    return parseFloat(value).toFixed(float);
  }
  dateformat(value) {
    return new DatePipe('en-Us').transform(value, 'dd-MM-yyyy HH:mm:ss', 'GMT+2');
  }

  changeFiatCurrency(currency: string) {    
    this.isWithdrawStatus(currency).subscribe(result => {// to check if the withdraw status is disabled for each currency
      if (result && !result.WithdrawStatus) {
        const messageKey = this.storageService.getLanguage() === 'es' ? 'Väljavõtmine on keelatud' : 'Withdraw is disabled';
        this.fiatErrorMessage = `${messageKey} ${currency}`;
      }
    });

    this.fiatCurrencyActive = this.fiatWallets.find(e => e.currency === currency);
    this.fiatWithdraw.currency = currency;
    this.fiatCurrencyActive.receive_amount = '';
    this.fiatCurrencyActive.fee_amount = '';
    this.fiatErrorMessage = '';
    this.fiatButton = true;
  }
  isWithdrawStatus(currency: string) {
    return this.userService.getCurrencies().pipe(
      map(response => response?.find(r => r.CurrencySymbol === currency))
    );
  }
  
  changeCryptoCurrency(currency: string) {
    this.isWithdrawStatus(currency).subscribe(result => {
      if (result && !result.WithdrawStatus) {
        const messageKey = this.storageService.getLanguage() === 'es' ? 'Väljavõtmine on keelatud' : 'Withdraw is disabled';
        this.cryptoErrorMessage = `${messageKey} ${currency}`;
      }
    });

    this.cryptoWithdraw.amount = '';
    if(this.cryptoWallets.length > 0){
      this.cryptoCurrencyActive = this.cryptoWallets.find(e => e.currency === currency); 
    }

    this.cryptoCurrencyActive.receive_amount = '';
    this.cryptoCurrencyActive.fee_amount = '';
    this.cryptoWithdraw.currency = currency;
    this.cryptoErrorMessage = '';

    this.activeBlockchainWithdraw = [];
    if(this.blockchainConfig){
      this.activeBlockchainWithdraw = this.blockchainConfig.filter(
        e => (e.CurrencySymbol == currency && e.IsActive == true));
  
      var defaultBlockchane = this.blockchainConfig.find(
          e => (e.CurrencySymbol == currency && e.IsActive == true && e.IsDefault == true));
  
      this.cryptoWithdraw.blockchain = defaultBlockchane?.Blockchain;
      this.cryptoWithdraw.assetid = defaultBlockchane?.FireBlockID;
    }

    console.log(this.allWhiteListAddresses)
    if(this.allWhiteListAddresses.length > 0)
      this.getwhitelistedaddress();
    if(currency === 'XRP')
      this.showTagWithdraw = true; 
    else {
      this.showTagWithdraw = false;
      this.cryptoWithdraw.destinationTag = '';
    }

  }
  refreshAPI() {
    this.getuserWhitelistStatus();
  }
  changeAddress(address) {
    var whiteListAddress = this.whiteListAddresses.find(e => e.address == address)
    this.cryptoWithdraw.des_tag = whiteListAddress.destinationTag;
  }

  changeWhitelistBlockchain(blockchain) {
    var blockchain = this.activeBlockchainWhitelist.find(e => e.Blockchain == blockchain)
    this.formwhitelist.assetid = blockchain.FireBlockID;
  }

  cryptoWithdrawSave() {

    if (this.performCryptoValidation()) {
      this.tradeService.Withdrawsubmit(this.cryptoWithdraw, 'Withdrawsubmit', this.storageService.getLanguage()).subscribe((result) => {
        if (result.status) {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", result.messagees)
          } else {
            this.notifyService.showSuccess("", result.message)
          }
          this.cryptoWithdraw.amount = '';
          this.cryptoCurrencyActive.fee_amount = '';
          this.cryptoWithdraw.note = '';
          this.cryptoButton = true;
        }
        else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", result.messagees)
          } else {
            this.notifyService.showError("", result.message)
          }
        }
      }, err => {
        console.log(err);
      });
    }
  }

  cryptoWithdrawFees() {
    this.feeError = '';
    this.cryptoCurrencyActive.fee_amount = '';
    var fee_message = '';
    if (this.storageService.getLanguage() == 'es') {
      fee_message = 'Tasu arvestatakse tehingu tegemise ajal'; 
    } else {
      fee_message = 'The fee will be calculated at the time of the transaction.';
    }
    if (this.performCryptoValidation()) {
      this.tradeService.cryptoWithdrawFees(this.cryptoWithdraw, this.storageService.getLanguage()).subscribe((result) => {
        if (result.status) {
          this.cryptoCurrencyActive.fee_amount = result.fee;
        }else{
          this.cryptoCurrencyActive.fee_amount = fee_message;
        }
      }, err => {
        this.cryptoCurrencyActive.fee_amount = fee_message;
        console.log(err);
      });
    }
  }

  fiatWithdrawSave() {
    if (this.performFiatValidation()) {
      this.userService.reduceBalanceRequest(this.fiatWithdraw, this.storageService.getLanguage()).subscribe((success) => {
        if (success.status == true) {

          this.fiatWithdraw.bank_name = '';
          this.fiatWithdraw.amount = '';
          this.fiatWithdraw.account_holder_name = '';
          this.fiatWithdraw.iban_number = '';
          this.fiatWithdraw.bic_code = '';
          this.fiatWithdraw.account_number = '';
          this.fiatWithdraw.ifsc_code = '';
          this.fiatCurrencyActive.fee_amount = '';
          this.fiatCurrencyActive.receive_amount = '';
          this.fiatButton = true;

          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", success.messagees)
          } else {
            this.notifyService.showSuccess("", success.message)
          }


        } else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", success.messagees)
          } else {
            this.notifyService.showError("", success.message)
          }

        }
      });
    }
  }
  withdrawconfirm(id) {
    this.data['id'] = id;
    // get profile detail
    this.userService.getProfileAPI().subscribe((response) => {
      this.data['userId'] = response._id;
      this.data['email'] = response.email;
      this.tradeService.withdrawconfirm(this.data, 'withdrawconfirm').subscribe((result) => {
        if (result.status) {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", result.messagees)
          } else {
            this.notifyService.showSuccess("", result.message)
          }


          // setTimeout(function () { window.location.href = "/withdraw"; }, 1000);
        }
        else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", result.messagees)
          } else {
            this.notifyService.showError("", result.message)
          }
        }
      });
    });
  }

  withdrawcancel(id) {
    this.data['id'] = id;
    // get profile detail
    this.userService.getProfileAPI().subscribe((response) => {
      this.data['userId'] = response._id;
      this.data['email'] = response.email;
      this.tradeService.withdrawcancel(this.data, 'withdrawcancel').subscribe((result) => {
        if (result.status) {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", result.messagees)
          } else {
            this.notifyService.showSuccess("", result.message)
          }


          //setTimeout(function () { window.location.href = "/withdraw"; }, 1000);
        }
        else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", result.messagees)
          } else {
            this.notifyService.showError("", result.message)
          }



        }
      });
    });
  }
  getwhitelistedaddress() {
      
    if (this.cryptoWithdraw.currency == '' || this.cryptoWithdraw.currency == undefined ||
        this.cryptoWithdraw.blockchain == '' || this.cryptoWithdraw.blockchain == undefined
      ){
        return;
      }

      this.whiteListAddresses = [];

      this.whiteListAddresses = this.allWhiteListAddresses.filter(
        e => (e.currency == this.cryptoWithdraw.currency 
          && e.status == 'APPROVED'
          && e.assetid == this.cryptoWithdraw.assetid ));
      if (this.whiteListAddresses.length > 0) {
        this.whitelist_status = true;
        this.cryptoWithdraw.address = this.whiteListAddresses[0].address;
        this.cryptoWithdraw.des_tag = this.whiteListAddresses[0].destinationTag;
      }else{
        this.whitelist_status = false;
        this.cryptoWithdraw.address = '';
      }
  }

  fetchWithdraw(currency = null) {
    this.searchObj['currency'] = currency ?? this.searchObj['currency'];
    // get withdraw detail
    this.tradeService.getwithdrawtrans("", "", this.searchObj.currency, false).subscribe((withdrawData) => {
      this.withdrawhistorylist = withdrawData.withdraw;
      this.bindCyptoWithdrawHistoryGrid(withdrawData.withdraw);
    }, (err) => {
      if (!this.userService.isLoggedIn()) {
        window.location.href = '/login'
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Jätkamiseks peate sisse logima.")
        } else {
          this.notifyService.showError("", "You must login to continue.")
        }

      } else {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Ilmnes viga, proovige uuesti.")
        } else {
          this.notifyService.showError("", "Error occured, please try again.")
        }
      }
    });
  }

  fetchWithdrawFiat(currency = null) {
    this.searchObj['currency'] = currency ?? this.searchObj['currency'];
    this.userService.getfiatwithdrawlistbyuserid(this.data, "", "", this.searchObj.currency, false).subscribe((fiatwithdralistresponse) => {
      this.fiatwithdrawlist = fiatwithdralistresponse.withdraw;
      this.bindFiatWithdrawHistoryGrid(fiatwithdralistresponse.withdraw);
    }, (fiatwithdrawerr) => {
      this.notifyService.showError('', fiatwithdrawerr.error.message);
    })
  }
  exportExcel(history: string) {
    if (history == 'crypto_withdraw') {
      this.tradeService.getwithdrawtrans("", "", this.searchObj.currency, true).subscribe((withdrawData) => {
        this.exportwithdrawhistory = [];
        var withdrawhistorylist = []
        withdrawhistorylist = withdrawData.withdraw;
        var deparr = []
        for (var i = 0; i < withdrawhistorylist.length; i++) {

          // var txn_id = '';
          // if (this.withdrawhistorylist[i].txntype == "Automatic") {
          //   txn_id = this.withdrawhistorylist[i].txnid ? this.withdrawhistorylist[i].txnid : 'N/A'
          // }
          // else {
          //   txn_id = this.withdrawhistorylist[i].transactionid ? this.withdrawhistorylist[i].transactionid : 'N/A';
          // }
          deparr.push({
            "Txnid": withdrawhistorylist[i].txnid ? withdrawhistorylist[i].txnid : 'nil',
            "txHash": withdrawhistorylist[i].txHash ? withdrawhistorylist[i].txHash : 'nil',
            "Currency": withdrawhistorylist[i].currency ? withdrawhistorylist[i].currency : 'nil',
            "Amount": withdrawhistorylist[i].amount ? this.round(withdrawhistorylist[i].amount, 5) : 'nil',
            "Fee": withdrawhistorylist[i].withdraw_fee ? this.round(withdrawhistorylist[i].withdraw_fee, 5) : 'nil',
            "Destination address": withdrawhistorylist[i].address ? withdrawhistorylist[i].address : 'nil',
            "Note": withdrawhistorylist[i].note ? withdrawhistorylist[i].note : 'nil',
            "Date of Transaction (CET)": withdrawhistorylist[i].createdAt ? this.dateformat(withdrawhistorylist[i].createdAt) : 'nil',
            "Status": withdrawhistorylist[i].status ? withdrawhistorylist[i].status : 'nil',
          })
        }
        this.exportwithdrawhistory = deparr;

        this.fileName = 'crypto_withdrawlist.xlsx';
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportwithdrawhistory);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, this.fileName);
      })

    } else {
      this.userService.getfiatwithdrawlistbyuserid(this.data, "", "", this.searchObj.currency, true).subscribe((fiatwithdralistresponse) => {
        var fiatwithdrawlist = []
        fiatwithdrawlist = fiatwithdralistresponse.withdraw;
        var temparr = []
        for (var i = 0; i < fiatwithdrawlist.length; i++) {
          temparr.push({
            "Blockoville txnID": fiatwithdrawlist[i].transactionid ? fiatwithdrawlist[i].transactionid : 'nil',
            "Currency": fiatwithdrawlist[i].currency ? fiatwithdrawlist[i].currency : 'nil',
            "Amount": fiatwithdrawlist[i].amount ? this.round(fiatwithdrawlist[i].amount, 2) : 'nil',
            "Bank Ref Id": fiatwithdrawlist[i].refnumber ? fiatwithdrawlist[i].refnumber : 'nil',
            "Date of Transaction (CET)": fiatwithdrawlist[i].createdAt ? this.dateformat(fiatwithdrawlist[i].createdAt) : 'nil',
            "Status": fiatwithdrawlist[i].status ? fiatwithdrawlist[i].status : 'nil',
          })
        }
        this.exportfiatwithdrawhistory = temparr;

        this.fileName = 'fiatWithdrawlist.xlsx';
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportfiatwithdrawhistory);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, this.fileName);
      })
    }
  }

  // whitelisting withdraw address
  whitelistSave(whitelist: NgForm) {
    this.submit = true;
    this.valid = true;
    if (this.formwhitelist.currency == '' || this.formwhitelist.currency == undefined) {
      if (this.storageService.getLanguage() == 'es') {
        this.placeHoldwhitelsit.error.currency = 'Valuuta väli on kohustuslik';
      } else {
        this.placeHoldwhitelsit.error.currency = 'Currency field required';
      }

      this.valid = false;
    }
    else {
      this.placeHoldwhitelsit.error.currency = "";
      this.valid = true;
    }
    if (this.formwhitelist.address == undefined || this.formwhitelist.address == '') {
      if (this.storageService.getLanguage() == 'es') {
        this.placeHoldwhitelsit.error.address = 'Aadressiväli on kohustuslik';
      } else {
        this.placeHoldwhitelsit.error.address = 'Address field is required';
      }
      this.valid = false;
    } else {
      this.placeHoldwhitelsit.error.address = "";
      this.valid = true;
    }
    if (this.formwhitelist.currency == 'XRP' &&
      (this.formwhitelist.destinationTag == undefined || this.formwhitelist.destinationTag == '')) {
      if (this.storageService.getLanguage() == 'es') {
        this.placeHoldwhitelsit.error.destinationTag = 'Sihtkoha silt on kohustuslik';
      } else {
        this.placeHoldwhitelsit.error.destinationTag = 'Destination Tag is required';
      }
      this.valid = false;
    } else {
      this.placeHoldwhitelsit.error.destinationTag = "";
      this.valid = true;
    }

    if (this.valid) {      
      this.tradeService.Whitelistsubmit(this.formwhitelist, 'whitelistsave', this.storageService.getLanguage()).subscribe((result) => {
        if (result.status) {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", result.messagees)
          } else {
            this.notifyService.showSuccess("", result.message)
          }
          this.formwhitelist.address, this.formwhitelist.note = "";
        }
        else {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", result.messagees)
          } else {
            this.notifyService.showError("", result.message)
          }
        }
        this.formwhitelist.address = '';
        this.formwhitelist.destinationTag = '';
        this.getuserWhitelistStatus();
      }, err => {
        console.log(err);
      });
    }
  }
  currencyChange(currency) {
    this.activeBlockchainWhitelist = [];
    this.activeBlockchainWhitelist = this.blockchainConfig.filter(
      e => (e.CurrencySymbol == currency && e.IsActive == true));

    var defaultBlockchane = this.blockchainConfig.find(
        e => (e.CurrencySymbol == currency && e.IsActive == true && e.IsDefault == true));

    this.formwhitelist.blockchain = defaultBlockchane?.Blockchain;
    this.formwhitelist.assetid = defaultBlockchane?.FireBlockID;
    //console.log(this.formwhitelist.blockchain)
    if(currency === 'XRP')
      this.showTagWhitelist = true; 
    else {
      this.showTagWhitelist = false;
      this.formwhitelist.destinationTag = '';
    }
  }
  deleteWhiteListAddress(data) {
    var message, ok, cancel = '';
    if (this.storageService.getLanguage() == 'es') {
      message = `Kas soovite kindlasti selle lubatud aadressi kustutada?`;
      ok = `Jah`;
      cancel = `Ei`;
    } else {
      message = `Are you sure want to delete this whitelist address?`;
      ok = `Yes`;
      cancel = `No`;
    }
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    }).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.formDeleteWhiteListAddress._id = data._id;
        this.userService.delWhitelist(this.formDeleteWhiteListAddress, this.storageService.getLanguage()).subscribe(data => {
          this.getuserWhitelistStatus();
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showSuccess("", data.messagees)
          } else {
            this.notifyService.showSuccess("", data.message)
          }
        })
      }
    });

  }

  whitelist_process(id) {
    this.whiteListAddressProcess.id = id;
    this.tradeService.whitelist_process(this.whiteListAddressProcess, 'whitelist_process').subscribe((result) => {
      if (result.status == true) {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showSuccess("", result.messagees)
        } else {
          this.notifyService.showSuccess("", result.message)
        }
      }
      else {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", result.messagees)
        } else {
          this.notifyService.showError("", result.message)
        }
      }
      setTimeout(() => {
        window.location.href = '/withdraw';
      }, 2000);
      this.getuserWhitelistStatus();
    });

  }
  performCryptoValidation() {
    let validCrypto = false;

    if (this.cryptoWithdraw.amount == '' || this.cryptoWithdraw.amount == null || Number(this.cryptoWithdraw.amount) < 0) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Sisestage kehtiv summa");
      } else {
        this.notifyService.showError('', "Please enter valid amount");
      }
    } else if (Number(this.cryptoWithdraw.amount) > this.cryptoCurrencyActive?.availableBalance) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Pole piisavalt tasakaalu");
      } else {
        this.notifyService.showError('', "Don't have a enough balance");
      }
    } else if (this.cryptoWithdraw.currency == 'XRP' && (this.cryptoCurrencyActive?.availableBalance - Number(this.cryptoWithdraw.amount)) < 10) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Reservtagatisraha vähemalt 10 XRP");
      } else {
        this.notifyService.showError('', "Reserve deposit of at least 10 XRP");
      }
    } else if (this.cryptoWithdraw.address == '' || this.cryptoWithdraw.address == null ) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Please whitelisting address first");
      } else {
        this.notifyService.showError('', "Please whitelisting address first");
      }
    } /*if (Number(this.cryptoWithdraw.amount) < this.cryptoCurrencyActive?.currency_config?.WithdrawMinimum) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud minimaalne kogus on " + this.cryptoCurrencyActive?.currency_config?.WithdrawMinimum);
      } else {
        this.notifyService.showError('', 'Allowed minimum amount is ' + this.cryptoCurrencyActive?.currency_config?.WithdrawMinimum);
      }
    } else if (Number(this.cryptoWithdraw.amount) > this.cryptoCurrencyActive?.currency_config?.WithdrawMaximum) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud maksimaalne kogus on " + this.cryptoCurrencyActive?.currency_config?.WithdrawMaximum);
      } else {
        this.notifyService.showError('', 'Allowed maximum amount is ' + this.cryptoCurrencyActive?.currency_config?.WithdrawMaximum);
      }
    }*/ else {
      validCrypto = true;
    }
    return validCrypto;
  }

  performFiatValidation() {
    let validFiat = false;
    if (this.fiatWithdraw.amount == '' || this.fiatWithdraw.amount == null || Number(this.fiatWithdraw.amount) < 0) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Sisestage kehtiv summa");
      } else {
        this.notifyService.showError('', "Please enter valid amount");
      }
    } else if (this.fiatWithdraw.bank_name == '') {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Panga nimi ei kehti");
      } else {
        this.notifyService.showError('', 'Bank name is not valid');
      }
    } else if (Number(this.fiatWithdraw.amount) < this.fiatCurrencyActive?.currency_config?.WithdrawMinimum) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud minimaalne kogus on " + this.fiatCurrencyActive?.currency_config?.WithdrawMinimum);
      } else {
        this.notifyService.showError('', 'Allowed minimum amount is ' + this.fiatCurrencyActive?.currency_config?.WithdrawMinimum);
      }
    } else if (Number(this.fiatWithdraw.amount) > this.fiatCurrencyActive?.currency_config?.WithdrawMaximum) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud maksimaalne kogus on " + this.fiatCurrencyActive?.currency_config?.WithdrawMaximum);
      } else {
        this.notifyService.showError('', 'Allowed maximum amount is ' + this.fiatCurrencyActive?.currency_config?.WithdrawMaximum);
      }
    } else if (Number(this.fiatWithdraw.amount) > this.fiatCurrencyActive?.availableBalance) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Pole piisavalt tasakaalu");
      } else {
        this.notifyService.showError('', "Don't have a enough balance");
      }
    } else if (this.fiatWithdraw.account_holder_name == '') {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Pangakonto omaniku nimi ei kehti");
      } else {
        this.notifyService.showError('', 'Bank Account Holder Name is not valid');
      }
    } else if (this.fiatWithdraw.iban_number == '' && this.fiatWithdraw.currency == 'EUR') {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Panga Iban number ei kehti");
      } else {
        this.notifyService.showError('', 'Bank Iban number is not valid');
      }
    } else if (this.fiatWithdraw.account_number == '' && this.fiatWithdraw.currency == 'INR') {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Pangakonto number Nimi ei kehti");
      } else {
        this.notifyService.showError('', 'Bank Account Number Name is not valid');
      }
    } else if (this.fiatWithdraw.ifsc_code == '' && this.fiatWithdraw.currency == 'INR') {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Panga IFSC code ei kehti");
      } else {
        this.notifyService.showError('', 'Bank IFSC code is not valid');
      }
    } else {
      validFiat = true;
    }
    return validFiat;
  }

  onCyptoWithdrawGridReady(params: GridReadyEvent) {
    this.cyptoWithdrawHistoryGridApi = params.api;
    this.cyptoWithdrawHistoryGridApi.sizeColumnsToFit();
    this.cyptoWithdrawHistoryGridApi.setDomLayout('autoHeight');
  }

  getCyptoHistoryRowNodeId(data) {
    return data['_id']
  }

  bindCyptoWithdrawHistoryGrid(data: any) {
    this.cyptoWithdrawHistoryGridApi.setRowData([]);
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.cyptoWithdrawHistoryGridApi.getRowNode(dt['_id']);
        if (selectedNode) {
          this.cyptoWithdrawHistoryGridApi.applyTransaction({ update: [dt] });
        } else {
          this.cyptoWithdrawHistoryGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }

  bindSingleCyptoWithdrawHistory(dt: any) {
    let selectedNode = this.cyptoWithdrawHistoryGridApi.getRowNode(dt['_id']);
    if (selectedNode) {
      this.cyptoWithdrawHistoryGridApi.applyTransaction({ update: [dt] });
    } else {
      this.cyptoWithdrawHistoryGridApi.applyTransaction({ add: [dt] })
    }
  }

  applyCyptoWithdrawHistoryFilter(event: any) {
    this.cyptoWithdrawHistoryGridApi.setQuickFilter(event.target.value)
  }



  //fiat currency
  onFiatHistoryGridReady(params: GridReadyEvent) {
    this.fiatWithdrawHistoryGridApi = params.api;
    this.fiatWithdrawHistoryGridApi.sizeColumnsToFit();
    this.fiatWithdrawHistoryGridApi.setDomLayout('autoHeight');
  }

  getFiatHistoryRowNodeId(data) {
    return data['otctxnid']
  }

  bindFiatWithdrawHistoryGrid(data: any) {
    this.fiatWithdrawHistoryGridApi.setRowData([]);
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.fiatWithdrawHistoryGridApi.getRowNode(dt['otctxnid']);
        if (selectedNode) {
          this.fiatWithdrawHistoryGridApi.applyTransaction({ update: [dt] });
        } else {
          this.fiatWithdrawHistoryGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }

  bindSingleFiatWithdrawHistory(dt: any) {
    let selectedNode = this.fiatWithdrawHistoryGridApi.getRowNode(dt['otctxnid']);
    if (selectedNode) {
      this.fiatWithdrawHistoryGridApi.applyTransaction({ update: [dt] });
    } else {
      this.fiatWithdrawHistoryGridApi.applyTransaction({ add: [dt] })
    }
  }

  applyFiatWithdrawHistoryFilter(event: any) {
    this.fiatWithdrawHistoryGridApi.setQuickFilter(event.target.value)
  }

  getAllBlockchainConfig() {
    this.userService.getAllBlockchainConfig().subscribe((result) => {
      if (result) {
        this.blockchainConfig = result;
        this.currencyChange(this.cryptoWithdraw.currency);
        this.changeCryptoCurrency(this.cryptoWithdraw.currency)
        this.activeBlockchainWithdraw = this.blockchainConfig.filter(
          e => (e.CurrencySymbol == this.cryptoWithdraw.currency && e.IsActive == true));
    
        var defaultBlockchane = this.blockchainConfig.find(
            e => (e.CurrencySymbol == this.cryptoWithdraw.currency && e.IsActive == true && e.IsDefault == true));
    
        this.cryptoWithdraw.blockchain = defaultBlockchane?.Blockchain;
        this.cryptoWithdraw.assetid = defaultBlockchane?.FireBlockID;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
